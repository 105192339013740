import { FormHelperText, TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import type { Control, FieldPath, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

interface Props<T extends FieldValues>
  extends Omit<TextareaAutosizeProps, 'name'> {
  control: Control<T>;
  name: FieldPath<T>;
}

const ControllerTextarea = <T extends FieldValues>(props: Props<T>) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <div >
          <TextareaAutosize
            id={name}
            style={{ width: '100%', borderColor: error ? 'red !important' : '' }}
            {...field}
            {...rest}
          />
          {error && (
            <FormHelperText error style={{ color: 'red' }}>
              {error.message}
            </FormHelperText>
          )}
        </div >
      )}
      name={name}
      control={control}
    />

  );
};

export default ControllerTextarea;
